import { Ref, ref } from 'vue'

import { UseApiPromise } from './types'

import { useApiOptions } from './internal/useApiOptions'
import { useApiShared } from './internal/useApiShared'
import { UseApiOptionsCache, UseApiOptionsShared, UseApiSharedReturnPrivate } from './internal/types'

export type UseApiManualOptions = Partial<
  & UseApiOptionsCache
  & Pick<UseApiOptionsShared<any, any>, 'cancelBeforeUnmount'>
>

export type UseApiManualReturn<Res, Req extends any[]> =
  & Omit<UseApiSharedReturnPrivate<Res, Req>, 'request'>
  & {
    request: (...data: Req) => Promise<Res | null>
    requestThrows: (...data: Req) => Promise<Res | null>
  }

/**
 * Wrapper for {@link useApi} for cases where you want to pass the request data into the request method
 * instead of the hook.
 * The request will not be executed immediately.
 *
 * @example
 * const { data, error, request, requestThrows } = useApiManual(api.postSomething)
 *
 * const handleSubmit = (data) => {
 *   await request(data)
 *
 *   if (error.value)
 *     // handle error
 *
 *   if (data.value)
 *     // handle data
 * }
 *
 * const handleSubmitAlt = (data) => {
 *   let newData
 *   try {
 *     newData = await requestThrows(data)
 *   } catch(error) {
 *     // handle error
 *   }
 *
 *   if (newData)
 *     // handle data
 * }
 */
export const useApiManual = <Res, Req extends any[]>(
  requestMethod: (...args: Req) => UseApiPromise<Res>,
  optionsPartial: UseApiManualOptions = {},
): UseApiManualReturn<Res, Req> => {
  const requestData = ref() as Ref<Req>
  const options = useApiOptions<Res, Req>(optionsPartial, {
    cancelBeforeUnmount: false,
  })
  const { request: requestInternal, ...hookInternal } = useApiShared(requestMethod, requestData, options)

  const request = (...data: Req) => {
    requestData.value = data
    return requestInternal()
  }

  const requestThrows = (...data: Req) => {
    requestData.value = data
    return requestInternal(true)
  }

  return {
    ...hookInternal,
    request,
    requestThrows,
  }
}
