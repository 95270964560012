export const ACCESS_ACTIONS = [
  'AdminOnlyAction',
  'List',
  'View',
  'Manage',
  'Launch',
  'Create',
  'Edit',
  'Delete',
  'AudienceDownloadMatchList',
  'CreativeAdmin',
  'CreativeTeam',
  'CreativeAdvertiser',
  'ApproveCreatives',
  'ManageAdvertisers',
  'ManageMarketers',
  'ManagePublishers',
  'ListAdvertisers',
  'ListMarketers',
  'ListPublishers',
  'ManageAnnouncements',
  'CreateOrEdit',
] as const

export type AccessAction = typeof ACCESS_ACTIONS[number]

export const ACCESS_RESOURCES = [
  'audiences',
  'accounts',
  'adunits',
  'analytics',
  'campaigns',
  'creativerequests',
  'creatives',
  'deals',
  'inventorylists',
  'locations',
  'measurement',
  'monetization',
  'placements',
  'reports',
  'settings',
  'users',
  'webevents',
  'directsoldcampaigns',
  'features',
  'plans',
  'marketplaceadgroups',
] as const

export type AccessResource = typeof ACCESS_RESOURCES[number]

export const ACCESS_FEATURES = [
  'ACCOUNTSETTINGS',
  'ACCOUNTSFILTERING',
  'ACTUALBUDGET',
  'ADMINONLY',
  'ANDOR',
  'AUDIENCEBACKLOG',
  'AUDIENCERELEASENOTIFICATION',
  'AUDIO-ACT-2',
  'BANNER-ACT-2',
  'BROWSERSBUYERS',
  'BULKCREATIVERESEND',
  'BULKOPERATION',
  'CAGMMB',
  'CAGMMBC',
  'CAGPT',
  'CAGRM',
  'CAGRMP',
  'CAMPAIGNISPROACT',
  'CHANGEHISTORY',
  'CMACPM',
  'CMCST',
  'CONNECTEDTV-ACT-2',
  'COSTUNT',
  'CPMCALC',
  'CREAT',
  'CREATEPUBLISHERUSERS',
  'CUSTOMWORKFLOWS',
  'DATAMON',
  'DATAONLY',
  'DATAONLY-ACT-2',
  'DIRECTSOLD-ACT-2',
  'DISCREPANCYREPORT',
  'EMAIL-ACT-2',
  'ENDEMIC',
  'ENDEMICHCP-ACT-2',
  'ENDEMICDTC-ACT-2',
  'ENDEMICREACHEXTENSION',
  'FB-CALC',
  'GBL_SEARCH',
  'GLOBALCACHE',
  'GLOBALCACHE:Audience',
  'GLOBALCACHE:Campaign',
  'GLOBALCASHE:ACCOUNTMANAGER',
  'GLOBAL_NAME_DISCOVERABILITY',
  'GOOGLEAUTH',
  'GROSSMETRICS',
  'HCPONLY',
  'HCP_SEGMENTATION',
  'LASSOTRIGGER',
  'LIVERAMP_CHANNEL',
  'MARK',
  'MARKETPLACE',
  'MEASUREMENT_HCPFULL_FILTER_AUTOMATION',
  'MEASUREMENT_REPORTS_CONFIG_AUTOMATION',
  'MICROSOFTAUTH',
  'MULTICHANNEL',
  'NEWTOBRAND',
  'P360SERVERDOWN',
  'PACINGOPTIONS',
  'PRICINGTIEREDITOR',
  'RAGMMB',
  'RAGMMBC',
  'RAGRCSD',
  'RAGRM',
  'RAGRMMP',
  'RAGRMP',
  'RBIFEE',
  'RDCST',
  'REPORT-QUERY',
  'REPORTS-ADMIN',
  'RESTRICTVISIBILITY',
  'RETARGETING',
  'RPFEE',
  'SALESCHANNEL',
  'SALESPERFORMANCEREPORT',
  'SALESRECEIPTREPORT',
  'SQLSPLITCALLS:CAMPAIGNTAB',
  'STATCACHE',

  // typo in db
  'THROTlE_CHANNEL',

  'USEAUDIENCEPREPARE',
  'USER-LOG',
  'VIDEO-ACT-2',
  'CAMPAIGN_MONITORING_V2',
  'DIRECTSOLDPLDONLY',
  'CONSENT_PREFERENCE',
  'COMPETITIVESEPARATION',
  'CAMPAIGN-OVERVIEW-QUICK-STATS-ACT2',
  'CAMPAIGN_DELIVERY_TAB',

  'REPORTING-2',
  'CAMPAIGN-PERFORMANCE-REPORT-2',
] as const

export type AccessFeature = typeof ACCESS_FEATURES[number]
