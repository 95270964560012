import { computed, reactive, toValue, watch } from 'vue'
import { Asserts, BaseSchema, TypeOf, ValidationError } from 'yup'
import { debouncedRef, watchDebounced, whenever } from '@vueuse/core'
import { InvalidSubmissionHandler } from 'vee-validate'
import { cloneDeep, pick } from 'lodash-es'

import { KeyOf } from '../../types'
import { useBetterForm } from '../useBetterForm'
import { nextFrame, objFromEntries, objKeys, objValues, sleep, truthy } from '../../utils'

import { useToast } from '../useToast'

import { useSteps } from './useSteps'
import {
  MultiStepFormStepState, MultiStepFormValidate, MultiStepFormValidateStep,
  MultiStepHandleSubmitFactory, MultiStepInvalidSubmissionContext, UseMultiStepFormOptions, UseMultiStepFormReturn,
} from './types'

/**
 * Wrapper over {@link useBetterForm} that provides functionality for working with multi-step forms,
 * such as step control, single-step and cross-step validation and invalid indicators.
 */
export const useMultiStepForm = <Schema extends BaseSchema, StepName extends string>({
  validationSchema,
  initialValues,
  steps,
  getAvailableSteps,
  showError,
  sharedFields = [],
  debounceBackgroundValidation = 300,
  stepValidStateDebounce = 100,
  stepEnterValidationDelay = 0,
}: UseMultiStepFormOptions<Schema, StepName>): UseMultiStepFormReturn<TypeOf<Schema>, Asserts<Schema>, StepName> => {
  type ValuesInput = TypeOf<Schema>
  type ValuesOutput = Asserts<Schema>
  type StepState = MultiStepFormStepState<KeyOf<ValuesInput>, StepName>

  const form = useBetterForm({
    validationSchema,
    initialValues,
    onMissingField: showError ?? useToast().error,
  })

  const {
    stepsByKey,
    availableSteps,
    currentStep,
    isFirstStep,
    isLastStep,

    addStepToVisited,
    addStepsBeforeToVisited,
    addAllStepsToVisited,
    isStepVisited,
    goToNextStep,
    goToPrevStep,
  } = useSteps({
    steps,
    getAvailableSteps: () => getAvailableSteps({
      steps,
      stepNames: steps.map(step => step.name),
      values: form.values,
    }),
  })

  // Since vee-validate doesn't track the state of nested fields unless they are mounted,
  // we have to track it ourselves for steps that are not currently active
  const stepValidStateBackup: Record<StepName, boolean> = reactive(objFromEntries(steps.map(step => [step.name, true])))

  const isStepValidInternal = (stepName: StepName) => {
    return stepValidStateBackup[stepName]
  }

  const validSteps = computed(() => steps.filter(step => isStepValidInternal(step.name)).map(step => step.name))
  const validStepsDebounced = debouncedRef(validSteps, stepValidStateDebounce)

  const isStepValid = (stepName: StepName) => {
    return validStepsDebounced.value.includes(stepName)
  }

  const isStepInvalid = (stepName: StepName) => {
    return !isStepValid(stepName)
  }

  const stepsState = reactive(
    objFromEntries(
      steps.map(step => [
        step.name,
        computed((): StepState => ({
          ...step,
          current: currentStep.value === step.name,
          valid: isStepValid(step.name),
          invalid: isStepInvalid(step.name),
          visited: isStepVisited(step.name),
        })) as unknown as StepState,
      ]),
    ),
  ) as Record<StepName, StepState>

  const availableStepsState = computed((): StepState[] => availableSteps.value.map(step => stepsState[step.name]!))

  const handleError = async (errorFields: string[]) => {
    await form.scrollToError({
      errorFields,
      fallbackErrorMessage: 'Please resolve errors on the current tab',
    })
  }

  const getValidationErrors = async () => {
    try {
      await toValue(validationSchema).validate(form.values, { abortEarly: false })

      return []
    }
    catch (error) {
      if (error instanceof ValidationError) {
        return [error, ...error.inner]
      }

      return []
    }
  }

  const validateAllStepsBackup = async () => {
    const errors = await getValidationErrors()

    const results = objFromEntries(
      availableSteps.value.map((step) => {
        const results = step.fields.map((field) => {
          return errors.every(error => !form.isMatchingPath(field, error.path ?? ''))
        })

        return [step.name, results.every(Boolean)] as const
      }),
    )

    Object.assign(stepValidStateBackup, results)

    return { results, valid: objValues(results).every(truthy) }
  }

  const validateFields = (fields: string[]) => {
    return fields.flatMap((field) => {
      return form.getStatesFromPath(field).map(async (state) => {
        return [state.path, (await form.validateField(state.path as any))] as const
      })
    })
  }

  const validateStep: MultiStepFormValidateStep<StepName> = async (stepName, { mode } = {}) => {
    const stepFields = stepsByKey[stepName]?.fields ?? []
    // In case nested fields aren't mounted, we rely on our own step validation
    const [stepResults, sharedResults, allStepsResults] = await Promise.all([
      Promise.all(validateFields(stepFields)),
      Promise.all(validateFields(sharedFields)),
      validateAllStepsBackup(),
    ])

    const allResults = [...stepResults, ...sharedResults]
    const valid = allResults.every(([, result]) => result.valid) && allStepsResults.results[stepName]

    await form.validate({ mode: 'validated-only' })

    if (!valid && mode !== 'silent') {
      const errorFields = allResults.filter(([, { valid }]) => !valid).map(([field]) => field)
      await handleError(errorFields)
    }

    return { results: objFromEntries(stepResults), valid }
  }

  const goToStepAndError = async (stepName: StepName) => {
    currentStep.value = stepName
    await nextFrame()
    await validateStep(stepName)
  }

  const validate: MultiStepFormValidate<ValuesInput> = async ({ mode } = {}) => {
    form.pathStates.value.forEach((state) => {
      state.touched = true
    })

    // Here the form is fully validated, so we don't need to rely on our own step validation
    const [result] = await Promise.all([form.validate(), validateAllStepsBackup()])

    addAllStepsToVisited()

    if (result.valid) {
      return result
    }

    if (mode !== 'silent') {
      if (isStepValidInternal(currentStep.value)) {
        currentStep.value = steps.find(step => !isStepValidInternal(step.name))?.name ?? currentStep.value
        await sleep(stepEnterValidationDelay)
      }

      await handleError(objKeys(result.errors))
    }

    return result
  }

  const handleStepSubmit = async (
    { onStepSubmit, onStepError, shouldSkipStepValidation, nextStep, mode }: {
      onStepSubmit: ((values: ValuesOutput) => unknown) | undefined
      onStepError: ((ctx: MultiStepInvalidSubmissionContext<ValuesInput>) => void) | undefined
      shouldSkipStepValidation: ((stepName: StepName) => boolean) | undefined
      nextStep: StepName | undefined
      mode: 'silent' | undefined
    },
  ) => {
    if (shouldSkipStepValidation?.(currentStep.value)) {
      if (nextStep) {
        currentStep.value = nextStep
      }
      else {
        goToNextStep()
      }

      return
    }

    const result = await validateStep(currentStep.value, { mode })
    const values = cloneDeep(form.values)

    form.submitting.value = true

    try {
      if (result.valid) {
        await onStepSubmit?.(values)

        if (nextStep) {
          currentStep.value = nextStep
        }
        else {
          goToNextStep()
        }
      }
      else {
        onStepError?.({
          values,
          errors: form.errors.value,
          // vee-validate types are impossible to match
          results: result.results as any,
        })
      }
    }
    finally {
      form.submitting.value = false
    }

    return undefined
  }

  const handleFullSubmit = async (
    { onFullSubmit, onFullError }: {
      onFullSubmit: (values: ValuesOutput) => unknown
      onFullError: InvalidSubmissionHandler<ValuesInput> | undefined
    },
  ) => {
    const result = await validate()
    const values = cloneDeep(form.values)

    form.submitting.value = true

    try {
      if (result.valid) {
        await onFullSubmit(values)

        return values
      }
      else {
        onFullError?.({ values, ...pick(result, ['errors', 'results']) })

        return undefined
      }
    }
    finally {
      form.submitting.value = false
    }
  }

  /**
   * The function returned from this factory can be used as a handler for both the step and form submit,
   * as well as for switching between tabs.
   *
   * @example
   * const onSubmit = handleSubmit(
   *   (values) => {}, // The form has been successfully validated, submit the data to the backend
   *   // The rest are optional
   *   {
   *     onFullError: () => {}, // Handle validation errors when trying to submit the form
   *     onStepSubmit: () => {}, // The step has been successfully validated
   *     onStepError: () => {}, // Step validation returned error
   *     shouldSkipStepValidation: () => true || false, // Whether to go to the next step without validating
   *     submitImmediately: () => true || false, // Whether proceed to the next step or submit immediately
   *   }
   * )
   *
   * // This will automatically submit step or form depending on if this is the last step
   * // The default behavior of the submit event will be prevented automatically
   * <form @submit="onSubmit($event)" />
   *
   * // This will do the same as above, but not scroll to the error/show toast if validation fails
   * <form @submit="onSubmit($event, { mode: 'silent' })" />
   *
   * // This will validate the current step and not the whole form and switch to the provided step on success,
   * // unless shouldSkipStepValidation returns true
   * <button @click="onSubmit('someStep')" />
   * <button @click="onSubmit('someStep', { mode: 'silent' })" />
   */
  const handleSubmit: MultiStepHandleSubmitFactory<ValuesInput, ValuesOutput, StepName> = (
    onFullSubmit,
    {
      onFullError,
      onStepSubmit,
      onStepError,
      shouldSkipStepValidation,
      submitImmediately,
    } = {},
  ) => {
    return async (eventOrNextStep?: Event | StepName, { mode }: { mode?: 'silent' } = {}) => {
      const event = eventOrNextStep instanceof Event ? eventOrNextStep : undefined
      const nextStep = (eventOrNextStep instanceof Event || !eventOrNextStep) ? undefined : eventOrNextStep

      if (event) {
        event.stopPropagation()
        event.preventDefault()
      }

      if (toValue(submitImmediately) || (isLastStep.value && !nextStep)) {
        return handleFullSubmit({ onFullSubmit, onFullError })
      }
      else {
        return handleStepSubmit({ onStepSubmit, onStepError, shouldSkipStepValidation, nextStep, mode })
      }
    }
  }

  watch(currentStep, async (newValue, oldValue) => {
    addStepToVisited(oldValue)
    addStepsBeforeToVisited(newValue)

    if (isStepVisited(newValue)) {
      await sleep(stepEnterValidationDelay)
      await validateStep(newValue)
    }
  }, { flush: 'sync' })

  let wasDirty = false

  whenever(form.dirty, () => {
    wasDirty = true
  })

  watchDebounced([form.values, currentStep, () => toValue(validationSchema), availableSteps], () => {
    if (wasDirty) {
      void validateAllStepsBackup()
    }
  }, { deep: true, debounce: debounceBackgroundValidation })

  return {
    ...form,
    validate,
    handleSubmit,

    currentStep,
    steps: stepsState,
    availableSteps: availableStepsState,
    isFirstStep,
    isLastStep,
    isStepValid,
    isStepInvalid,
    isStepVisited,
    validateStep,
    goToNextStep,
    goToPrevStep,
    goToStepAndError,
  }
}
