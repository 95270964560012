import { Ref, computed, nextTick, ref, shallowRef, toValue, watch } from 'vue'
import { createEventHook, tryOnBeforeUnmount, until } from '@vueuse/core'

import { RefOrGetter, ToRefObject } from '../../../types'
import { UseApiPromise, UseApiRequestArgNormalized, UseApiRequestMethod } from '../types'
import { ApiCacheRecord, useApiCache } from '../useApiCache'
import { Awaitable, toAwaitable } from '../../../utils'

import { useApiCacheFor } from '../useApiCacheFor'

import {
  UseApiAfterRequestContext,
  UseApiOptionsCache,
  UseApiOptionsRefetch,
  UseApiOptionsShared,
  UseApiSharedReturnPrivate,
} from './types'

export type UseApiSharedState<Res, Req> = {
  finished: Ref<boolean>
  loaded: Ref<boolean>
  loading: Ref<boolean>
  fetching: Ref<boolean>
  canceled: Ref<boolean>
  error: Ref<unknown>
  data: Ref<Res | null>
  lastRequestData: Ref<Req | null>
  clearData: () => void
  clearOnNoRequest: () => void
}

export type UseApiSharedRequest<Res> = {
  request: (throwOnError?: boolean) => Promise<Res | null>
  retry: () => Promise<void>
  cancel: () => void
}

export type UseApiSharedEventHooks<Res, Req> = {
  onData: (cb: (responseData: Res, requestData: Req) => void) => { off: () => void }
  onError: (cb: (error: unknown) => void) => { off: () => void }
  onFinally: (cb: () => void) => { off: () => void }
  onCancel: (cb: () => void) => { off: () => void }
}

export type UseApiSharedCache<Res> = {
  cache: RefOrGetter<ApiCacheRecord<UseApiPromise<Res>> | null>
  clearCache: () => void
}

export const useApiSharedRequest = <Res, Req extends any[]>(
  requestMethod: UseApiRequestMethod<Res, Req>,
  requestData: UseApiRequestArgNormalized<Req>,
  onCancel: (lastRequestData: Req | null, lastPromise: UseApiPromise<Res>) => void,
  options: ToRefObject<UseApiOptionsShared<Res, Req>>,
):
  & UseApiSharedState<Res, Req>
  & UseApiSharedRequest<Res>
  & UseApiSharedEventHooks<Res, Req> => {
  const finished = ref(true)
  const loading = ref(false)
  const canceled = ref(false)
  const error = shallowRef<unknown>(null)
  const data = shallowRef<Res | null>(options.initialData.value)
  const fetching = computed(() => loading.value && !data.value)
  const loaded = computed(() => !loading.value && !error.value && Boolean(data.value))
  let lastPromise: UseApiPromise<Res> | null = null
  const lastRequestData: Ref<Req | null> = ref(null)

  const dataEvent = createEventHook<[Res, Req]>()
  const errorEvent = createEventHook<unknown>()
  const finallyEvent = createEventHook<void>()
  const cancelEvent = createEventHook<void>()

  const setLoading = (isLoading: boolean) => {
    loading.value = isLoading
    finished.value = !isLoading
  }

  const cancel = () => {
    if (!lastPromise) {
      return
    }

    const _lastPromise = lastPromise

    onCancel(lastRequestData.value, _lastPromise)
    canceled.value = true
    cancelEvent.trigger()
    lastPromise = null
    setLoading(false)

    if ('cancel' in _lastPromise) {
      _lastPromise?.cancel()
    }
  }

  const isCancel = (responseError: unknown) => {
    return responseError && typeof responseError === 'object' && (
      // OpenAPI generated CancelError
      ('name' in responseError && responseError.name === 'CancelError')
      // Axios cancel error
      || '__CANCEL__' in responseError
    )
  }

  const afterRequest = (responseData: Res, curRequestData: Req) => {
    ({ responseData } = options.afterRequest.value({
      currentData: data.value,
      responseData,
      requestData: curRequestData,
    }))

    data.value = responseData
    canceled.value = false
    dataEvent.trigger([responseData, curRequestData])

    return responseData
  }

  const clearData = () => {
    data.value = options.initialData.value
    error.value = null
    lastRequestData.value = null
  }

  const clearOnNoRequest = () => {
    if (options.clearWhenDisabled.value) {
      clearData()
    }
    else {
      lastRequestData.value = null
    }
  }

  const request = async (throwOnFailed = false): Promise<Res | null> => {
    const curRequestData = options.enabled.value && toValue(requestData)

    if (!curRequestData) {
      clearOnNoRequest()

      return null
    }

    lastRequestData.value = curRequestData

    cancel()
    setLoading(true)
    error.value = null
    canceled.value = false

    const curPromise = lastPromise = requestMethod(...curRequestData)

    try {
      const responseData: Res = await lastPromise

      if (curPromise !== lastPromise) {
        return null
      }

      return afterRequest(responseData, curRequestData)
    }
    catch (responseError) {
      if (curPromise !== lastPromise) {
        return null
      }

      if (isCancel(responseError)) {
        canceled.value = true
        cancelEvent.trigger()
        return null
      }

      clearData()
      error.value = responseError
      canceled.value = false

      errorEvent.trigger(responseError)

      if (throwOnFailed) {
        throw responseError
      }

      return null
    }
    finally {
      if (curPromise === lastPromise) {
        lastPromise = null
        setLoading(false)
        finallyEvent.trigger()
      }
    }
  }

  const retry = async () => {
    if (error.value) {
      await request()
    }
  }

  tryOnBeforeUnmount(() => {
    if (options.cancelBeforeUnmount.value) {
      cancel()
    }
  })

  return {
    finished,
    loaded,
    loading,
    fetching,
    canceled,
    error,
    data,
    lastRequestData,

    request,
    retry,
    cancel,
    clearData,
    clearOnNoRequest,

    onData: callback => dataEvent.on(([responseData, requestData]) => callback(responseData, requestData)),
    onError: errorEvent.on,
    onFinally: finallyEvent.on,
    onCancel: cancelEvent.on,
  }
}

const useApiSharedCache = <Res, Req extends any[]>(
  requestMethod: UseApiRequestMethod<Res, Req>,
  options: ToRefObject<UseApiOptionsCache & UseApiOptionsShared<Res, Req>>,
) => {
  const cacheKey = computed(() => {
    const cacheOption = options.cache.value

    if (!cacheOption) {
      return null
    }

    return cacheOption === true ? requestMethod : cacheOption
  })

  const clearCacheFor = options.clearCacheFor.value.length > 0 ? useApiCache().clearCacheFor : () => {}

  const cache = useApiCacheFor<UseApiPromise<Res>>(cacheKey)

  const clearCache = () => {
    cache.value?.clear()
  }

  const getCachedPromise = (requestData: Req): UseApiPromise<Res> | null => {
    if (!cache.value) {
      return null
    }

    return cache.value.get(requestData) ?? null
  }

  const setCachedPromise = (requestData: Req, promise: UseApiPromise<Res>): void => {
    if (!cache.value) {
      return
    }

    cache.value.set(requestData, promise, options.cacheLifetime.value)
  }

  const cachedRequestMethod = (...curRequestData: Req) => {
    const cachedPromise = getCachedPromise(curRequestData)
    const curPromise = cachedPromise ?? requestMethod(...curRequestData)
    setCachedPromise(curRequestData, curPromise)

    // Ensure the promise isn't resolved in the same tick, that tends to break things
    return cachedPromise ? nextTick().then(() => curPromise) : curPromise
  }

  const afterRequest = (ctx: UseApiAfterRequestContext<Res, Req>) => {
    clearCacheFor(options.clearCacheFor.value)

    return options.afterRequest.value(ctx)
  }

  const removeFromCache = (requestData: Req | null, promise: UseApiPromise<Res>): void => {
    if (!cache.value) {
      return
    }

    if (requestData && cache.value.get(requestData) === promise) {
      cache.value.remove(requestData)
    }
  }

  return {
    cache,
    clearCache,
    cachedRequestMethod,
    afterRequest,
    removeFromCache,
  }
}

export const useApiShared = <Res, Req extends any[]>(
  requestMethod: UseApiRequestMethod<Res, Req>,
  requestData: UseApiRequestArgNormalized<Req>,
  options: ToRefObject<UseApiOptionsShared<Res, Req> & UseApiOptionsCache>,
): UseApiSharedReturnPrivate<Res, Req> => {
  const { cache, clearCache, cachedRequestMethod, afterRequest, removeFromCache } = useApiSharedCache(requestMethod, options)

  const hook = useApiSharedRequest(
    cachedRequestMethod,
    requestData,
    removeFromCache,
    {
      ...options,
      afterRequest: ref(afterRequest),
    },
  )

  return {
    ...hook,
    cache,
    clearCache,
  }
}

export const useRefetchOnRequestDataChange = <Res, Req extends any[]>(
  requestData: UseApiRequestArgNormalized<Req>,
  hook: UseApiSharedRequest<Res> & UseApiSharedState<Res, Req>,
  options: ToRefObject<UseApiOptionsRefetch<Req> & UseApiOptionsShared<Res, Req>>,
) => {
  watch(
    [options.refetch, options.enabled, requestData],
    ([refetch, enabled, curRequestData]) => {
      if (!refetch) {
        return
      }

      if (enabled && curRequestData && !options.equalityCheck.value(curRequestData as Req, hook.lastRequestData.value)) {
        void hook.request()
      }
      else if (!curRequestData || !enabled) {
        hook.clearOnNoRequest()
      }
    })
}

export const useRefetchOnCacheClear = <Res, Req extends any[]>(
  hook: UseApiSharedRequest<Res> & UseApiSharedState<Res, Req> & UseApiSharedCache<Res>,
  options: ToRefObject<UseApiOptionsRefetch<Req>>,
) => {
  const onCacheClear = async () => {
    await hook.request()
  }

  watch([hook.cache, options.refetchOnCacheClear], ([cache, refetchOnCacheClear], [prevCache]) => {
    if (prevCache && prevCache !== cache) {
      prevCache.offClear(onCacheClear)
    }

    if (!cache) {
      return
    }

    cache.offClear(onCacheClear)

    if (refetchOnCacheClear) {
      cache.onClear(onCacheClear)
    }
  }, { immediate: true, flush: 'post' })
}

export const useApiAwaitable = <T extends { finished: Ref<boolean> }>(hook: T): Awaitable<T> => {
  return toAwaitable(hook, until(hook.finished).toBe(true))
}
