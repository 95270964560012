import { isRef, toValue } from 'vue'
import { isEqual } from 'lodash-es'

import { objEntries, objFromEntries, toWritableRef } from '../../../utils'
import { ToRefObject } from '../../../types'

import { UseApiRequestArg, UseApiRequestArgNormalized } from '../types'

import { UseApiOptions } from './types'

const defaultOptions: UseApiOptions<any, any> = {
  immediate: true,
  refetch: false,
  refetchOnCacheClear: false,
  equalityCheck: isEqual,
  cache: false,
  cacheLifetime: 1000 * 60 * 10,
  clearWhenDisabled: false,
  initialData: null,
  afterRequest: ctx => ({ responseData: ctx.responseData }),
  clearCacheFor: [],
  enabled: true,
  cancelBeforeUnmount: true,
}

export const useApiArgs = <Req extends any[], OptionsArg extends Partial<Record<string, unknown>>>(args: unknown[]) => {
  let requestData: UseApiRequestArgNormalized<Req>
  let optionsPartial: Partial<OptionsArg>

  const isRequestData = (arg: unknown): arg is UseApiRequestArgNormalized<Req> => {
    return typeof arg === 'function' || isRef(arg) || Array.isArray(arg)
  }

  const normalizeRequestData = (data: UseApiRequestArg<Req>): UseApiRequestArgNormalized<Req> => {
    if (!Array.isArray(data) || data.every(arg => !isRef(arg))) {
      return data as UseApiRequestArgNormalized<Req>
    }

    return (() => data.map(arg => toValue(arg))) as UseApiRequestArgNormalized<Req>
  }

  if (isRequestData(args[0])) {
    requestData = normalizeRequestData(args[0])
    optionsPartial = (args[1] ?? {}) as Partial<OptionsArg>
  }
  else {
    requestData = [] as unknown as UseApiRequestArgNormalized<Req>
    optionsPartial = (args[0] ?? {}) as Partial<OptionsArg>
  }

  return { requestData, optionsPartial }
}

// Adds default values to options and normalizes them to an object with ref properties
export const useApiOptions = <Res, Req>(
  options: Partial<UseApiOptions<Res, Req>>,
  defaultOverrides: Partial<UseApiOptions<Res, Req>> = {},
): ToRefObject<UseApiOptions<Res, Req>> => {
  return objFromEntries(
    objEntries(defaultOptions).map(([key, defaultValue]) => {
      return [key, toWritableRef(options, key, defaultOverrides[key] ?? defaultValue)]
    }),
  ) as unknown as ToRefObject<UseApiOptions<Res, Req>>
}
